import api from "./revenuePlannerApi";

const path = "/configuration";

const configuration = {
  get: (accessToken, parent, name) =>
    api.get(`${path}/getconfiguration/${parent}/${name}`, accessToken),
};

export default configuration;
