import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, { useState } from "react";
import {
  formatNumber,
  toLocaleShortMonthAndYear,
} from "../common/formatHelper";

import { ReactComponent as ChartBarIcon } from "../../components/icons/chart_bar_icon.svg";
import { ReactComponent as ChartLineIcon } from "../../components/icons/chart_line_icon.svg";
import dateHelper from "../common/dateHelper";
import forecastViewType from "../../constants/forecastViewType";

const ForecastChart = (props) => {
  const [, , getDateInUTC] = dateHelper();
  const [legendDisplay, setLegendDisplay] = useState({});
  const xAxisSecondaryLabels = [];
  const todayDateLabel = toLocaleShortMonthAndYear(
    props.config.referenceLineDate
      ? getDateInUTC(props.config.referenceLineDate)
      : new Date()
  );
  const showXAxisYearsLabelAfterMonths = props.config
    .showXAxisYearsLabelAfterMonths
    ? props.config.showXAxisYearsLabelAfterMonths
    : 12;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="chart-tooltip">
          <div className="chart-label">{label}</div>
          {payload.map((p) => {
            return (
              <div className="chart-item-container" key={p.name}>
                <div className="pill" style={{ backgroundColor: p.fill }}></div>
                <div className="chart-item">{`${p.name} : ${formatNumber(
                  p.value,
                  props.config.decimalPlaces
                )} `}</div>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const CustomLegend = ({ payload }) => {
    if (payload && payload.length) {
      return (
        <ul className="recharts-default-legend" key={"recharts-legend-ul"}>
          {payload.map((p) => {
            const color = legendDisplay[p.dataKey] ? "#b0bac5" : p.color;
            return (
              <li
                className="recharts-legend-item"
                style={{
                  color: color,
                }}
                key={p.value}
                onClick={(e) => handleLegendOnClick(p)}
              >
                {p.type === "line" ? (
                  <ChartLineIcon></ChartLineIcon>
                ) : (
                  <ChartBarIcon></ChartBarIcon>
                )}

                <span
                  className="recharts-legend-item-text"
                  style={{ color: color }}
                >
                  {p.value}
                </span>
              </li>
            );
          })}
        </ul>
      );
    }

    return null;
  };

  const handleLegendOnClick = (o) => {
    const { dataKey } = o;
    setLegendDisplay((op) => ({ ...op, [dataKey]: !op[dataKey] }));
  };

  const labelRender = (args) => {
    var value = parseInt(args);
    if (value >= 1000 && value < 99999) {
      var kText = (value / 1000).toFixed(2);
      return kText + "K";
    } else if (value > 99999 && value < 1000000000) {
      var text = (value / 1000000).toFixed(2);
      return text + "M";
    } else if (value > 999999999) {
      var gText = (value / 1000000000).toFixed(2);
      return gText + "G";
    }

    return args;
  };

  const xAxisPrimaryTickFormatter = (tick) => {
    if (props.data.length > showXAxisYearsLabelAfterMonths) {
      return tick.substring(0, 3);
    }
    return tick;
  };

  const xAxisSecondaryTickRender = (tickProps) => {
    const { x, y, payload } = tickProps;
    const { value } = payload;
    const date = new Date(value);
    const year = date.getFullYear();

    const yearExists = xAxisSecondaryLabels.find((f) => f.year === year);

    if (yearExists) {
      const forecastdateExists = xAxisSecondaryLabels.find(
        (f) => f.forecastdate === value
      );

      if (forecastdateExists) {
        return <text x={x} y={y} textAnchor="middle">{`${year}`}</text>;
      }
    } else {
      xAxisSecondaryLabels.push({ year: year, forecastdate: value });
      return <text x={x} y={y} textAnchor="middle">{`${year}`}</text>;
    }

    return null;
  };

  return (
    <div className="col-12">
      <div className="card shadow-sm rounded-extra-lg pt-3">
        <div
          className="card-body ps-0"
          key="forecastchart"
          style={{ width: "100%", height: 320 }}
        >
          <ResponsiveContainer>
            <ComposedChart data={props.data}>
              <CartesianGrid stroke="#dfe6ee" />
              <XAxis
                xAxisId="0"
                dataKey="label"
                stroke="#b0bac5"
                interval={0}
                tickFormatter={xAxisPrimaryTickFormatter}
              />
              {props.data.length > showXAxisYearsLabelAfterMonths ? (
                <XAxis
                  xAxisId="1"
                  dataKey="forecastdate"
                  stroke="#b0bac5"
                  axisLine={false}
                  tickLine={false}
                  tick={xAxisSecondaryTickRender}
                  interval={0}
                  height={1}
                />
              ) : null}

              <YAxis tickFormatter={labelRender} stroke="#b0bac5" width={80} />
              {props.currentView === forecastViewType.HistoricView ? (
                <ReferenceLine
                  x={todayDateLabel}
                  strokeWidth={2}
                  stroke={
                    props.config.referenceLineColor
                      ? props.config.referenceLineColor
                      : "#ff6253"
                  }
                  strokeDasharray="3 3"
                />
              ) : null}
              <Tooltip content={<CustomTooltip />} />
              <Legend content={<CustomLegend />} />
              {props.config.attributeConfigurations
                .filter(
                  (c) => c.isVisible && c.chartType.toLowerCase() !== "none"
                )
                .map((c) =>
                  c.chartType === "Line" ? (
                    <Line
                      type="linear"
                      dataKey={c.attributeName.toLowerCase()}
                      stroke={c.chartColor}
                      fill={c.chartColor}
                      name={c.displayName}
                      hide={legendDisplay[c.attributeName.toLowerCase()]}
                      key={c.attributeName.toLowerCase()}
                    />
                  ) : (
                    <Bar
                      dataKey={c.attributeName.toLowerCase()}
                      barSize={25}
                      fill={c.chartColor}
                      name={c.displayName}
                      hide={legendDisplay[c.attributeName.toLowerCase()]}
                      key={c.attributeName.toLowerCase()}
                    />
                  )
                )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default ForecastChart;