import React, { useEffect, useState } from "react";

import ConfigApi from "../../api/configurationApi";
import Error from "../common/ServerError";
import Spinner from "../common/Spinner";
import { tokenRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";

const Reports = () => {
  const { instance, accounts } = useMsal();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [configuration, setConfiguration] = useState();

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    setLoading(true);

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;

        (async () => {
          await ConfigApi.get(accessToken, "Global", "PowerBIReportSource")
            .then((data) => {
              setError(null);
              if (data && data.value) {
                setConfiguration(data.value);
              }
            })
            .catch((error) => {
              setError(error);
              console.log(error);
            })
            .finally(() => setLoading(false));
        })();
      })
      .catch((error) => {
        setError(error);
        console.log(error);
      });
  }, [instance, accounts]);

  if (error) {
    return <Error error={error} />;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <div className="row pt-3 ps-2">
        <div className="col-12">
          {configuration ? (
            <div className="ratio ratio-21x9">
              <iframe
                title="power-bi-report"
                src={configuration}
                allowFullScreen="true"
              ></iframe>
            </div>
          ) : (
            <div className="alert alert-warning" role="alert">
              Please contact support to setup configuration to view the Reports
            </div>
          )}

          <div className="row"></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Reports;
